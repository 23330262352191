<template>
  <v-card :loading="isLoading">
    <div class="card card-custom gutter-b example example-compact">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="originalData.name">
            <small>Gider Türü</small><br />
            <span>{{ originalData.name }}</span>
          </div>
          <span v-else>Gider Türü</span>
          <div>
            <small v-if="cluster">{{ cluster.name }} Gider Türü</small>
          </div>
        </h3>
        <v-col class="text-right p-0">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col sm="12">
              <rs-text-field
                label="Adı"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
                :readonly="disabled || (id && !originalData.cluster_id)"
                :filled="disabled || (id && !originalData.cluster_id)"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            @submit.prevent="handleFormSubmit"
          />
        </v-form>
        <ActionLogList ref="actionLogList" />
      </v-card-text>
    </div>
    <v-card>
      <v-card-text v-if="clusterId">
        <v-data-table
          :fixed-header="false"
          :height="undefined"
          v-if="clusterId"
          v-bind="dataTableAttrs"
          :headers="headersShown"
          :items="list"
          :loading="isListLoading"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page.sync="itemsPerPage"
          v-model="selectedItems"
          @update:items-per-page="handleItemsPerPageUpdated"
        >
          <template v-slot:top>
            <rs-table-title-bar
              v-if="$refs.headSearch"
              title="Gider Tipleri"
              icon=""
              :add-route="
                can('edit-expense-type')
                  ? {
                      name: 'other-definitions.child-expense-types.parent-id.create',
                      query: { parent_id: originalData.id },
                    }
                  : null
              "
              @clear-filters="$refs.headSearch.reset"
              @reload="loadList"
              :hide-edit="!can('edit-expense-type')"
              :edit-enabled="selectedItems.length === 1"
              :search.sync="search.query"
              @click:edit="handleEditClick"
              @click:delete="handleDeleteClick"
              :show-delete="can('edit-expense-type')"
              :delete-enabled="selectedItems.length === 1"
            />
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:body.prepend="{ headers }">
            <rs-table-head-search
              :headers="headers"
              :search.sync="search"
              ref="headSearch"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.edit="{ item }">
            <router-link
              class="btn btn-icon btn-sm btn-clean"
              :to="{
                name: 'other-definitions.child-expense-types.edit',
                query: { parent_id: originalData.id },
                params: { id: item.id },
              }"
            >
              <i class="menu-icon mdi mdi-pencil"></i>
            </router-link>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{
                name: 'other-definitions.child-expense-types.show',
                params: { id: item.id },
              }"
            >
              {{ item.name }}
            </router-link>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.is_active="{ value }">
            <rs-table-cell-boolean :value="value" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDelete"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasDataTable, hasPermissions } from "@/view/mixins";

import debounce from "@/core/plugins/debounce";
import ClusterService from "@/core/services/cluster.service";

export default {
  mixins: [hasForm, hasDataTable, hasPermissions],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ActionLogList,
  },
  watch: {
    id() {
      this.load();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "cluster", "expenseTypeList"]),
    customEnabled() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (!item.custom) {
          return false;
        }
      }

      return true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
        this.loadList();
      }
    });
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-expense-type"),
        },
        {
          text: "Gider Tipi",
          value: "name",
          searchable: "text",
          options: () => this.expenseTypeList,
          sortable: true,
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
      isListLoading: false,
      formData: {
        cluster_id: null,
        name: null,
        is_active: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`types/expense-types/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (
            this.originalData.cluster_id &&
            this.clusterId !== this.originalData.cluster_id
          ) {
            this.updateClusterId(this.originalData.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadList() {
      this.selectedItems = [];
      this.isListLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;
      params.sign = 1;
      params.child_only = 1;
      params.parent_id = this.id;

      this.$api
        .query(`types/expense-types`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isListLoading = false));
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`types/expense-types/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`types/expense-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.expense-types.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "other-definitions.child-expense-types.edit",
          params: { id: this.selectedItems[0].id },
          query: { parent_id: this.originalData.id },
        });
      }
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`types/expense-types/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          ClusterService.loadExpenseTypeList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} gider tipini silmek istediğinizden emin misiniz?`
      );
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Type\\ExpenseType", this.id);
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
